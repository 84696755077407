//SCSS
import '../scss/main.scss';
//Vendor-scripts
import './vendor/slick.min.js'
//Bootstrap
import 'bootstrap';

//HTML
import '../../index.html';

$(document).ready(function() {
   $('.section-rooms__slider').slick({
       slidesToShow: 4,
       slidesToScroll: 1,
       responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
          }
        },
        {
            breakpoint: 521,
            settings: {
              slidesToShow: 1,
            }
          },
      ]
   });
});
